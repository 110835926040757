import firebase from "firebase";
import Vue from "vue";
const db = firebase.firestore();
import Axios from "axios";
import VueAxios from "vue-axios";
import axios from "axios";
import moment from 'moment';

Vue.use(VueAxios, Axios);

const postsCollection = db.collection('postList');

const getUserData = async (userId) => {
    try {
        if (userId) {
            const user = await db.collection('users').doc(userId).get();
            if (user.exists) {
                return { id: user.id, ...user.data() };
            }
        }
        return undefined;
    } catch (error) {
        console.error(error);
    }
}

const populateComment = async (comment) => {
    try {
        const user = await getUserData(comment.userId);
        comment.user = user;
        return comment;
    } catch (error) {
        console.error(error);
        return comment;
    }
}

const getComments = async (postId) => {
    try {
        const comments = await postsCollection.doc(postId).collection('comments').get();
        const commentsData = await Promise.all(comments.docs.map(async c => {
            return await populateComment({ ...c.data(), id: c.id });
        }));
        return commentsData;
    } catch (error) {
        console.error(error);
        return [];
    }
}

const populatePump = async (pump) => {
    try {
        const user = await getUserData(pump.userId);
        pump.user = user;
        return pump;
    } catch (error) {
        console.error(error);
        return pump;
    }
}

const getPumps = async (postId) => {
    try {
        const pumps = await postsCollection.doc(postId).collection('likes').get();
        const pumpsData = await Promise.all(pumps.docs.map(async p => {
            return await populatePump({ ...p.data(), id: p.id });
        }));
        return pumpsData;
    } catch (error) {
        console.error(error);
        return [];
    }
}

const getArticleData = async (articleRef) => {
    try {
        const article = await articleRef.get();
        const createdAt = article.data().createdAt.toDate();
        return { ...article.data(), id: article.id, createdAt: article.data().createdAt.toDate() };
    } catch (error) {
        console.error(error);
    }
}

export default {
    state: {},
    mutations: {},
    actions: {
        async getPostData({ commit }, postId) {
            try {
                const post = await db.collection('postList').doc(postId).get();
                if (post.exists) {

                    const postData = { ...post.data(), id: post.id, createdAt: post.data().createdAt.toDate() };

                    const populatedData = await Promise.all([
                        getComments(postId),
                        getPumps(postId),
                        getUserData(postData.userId)
                    ]);

                    postData.comments = populatedData[0];
                    postData.likes = populatedData[1];
                    postData.author = populatedData[2];

                    return postData;
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    getters: {},
};
