import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { requestEndPoint } from '@/store/Api'
import { apiKey } from '@/firebase'
import moment from "moment";
const _ = require('lodash');

Vue.use(VueAxios, Axios);

const db = firebase.firestore()
// db.settings({
//   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
//   synchronizeTabs:true
// });

// db.enablePersistence()

export default {
  state: {
    admin: {},
    users: [],
    usersList: [],
    actualUsersPage: [],
    quantityUsers: 0,
    unsubscribeGetQuantityUsers: null,
    actualPageUsersList: 1,
    usersToUpdateList: [],
    nextPageToken: undefined,
    counterUpdate: 0,
    dateRangeUsers: {
      startDate: new Date(new Date().setHours(0, 0, 0, 0)),
      endDate: new Date(new Date().setHours(23, 59, 59, 999))
    }
  },
  mutations: {
    SET_ADMIN(state, admin) {
      state.admin = admin;
    },
    LOGOUT(state) {
      state.admin = {};
    },
    SET_USERS(state, data) {
      if (state.users.length < 1) {
        state.users = data.users
      } else {
        let mutateArray = state.users.concat(data.users)
        state.users = mutateArray
      }
    },
    SET_ACTUAL_USERS_PAGE(state, sorts) {
      if (sorts.itemsPerPage > 0) {
        state.actualUsersPage = state.users.slice((sorts.page - 1) * sorts.itemsPerPage, sorts.page * sorts.itemsPerPage)
      }
    },
    RESET_VALUES_USERS(state) {
      state.users = []
      state.actualUsersPage = []
    },
    SET_QUANTITY_USERS(state, quantity) {
      state.quantityUsers = quantity
    },
    SET_UNSUBSCRIBE_GET_QUANTITY_USERS(state, unsubscribe) {
      state.unsubscribeGetQuantityUsers = unsubscribe
    },
    GO_UNSUBSCRIBE_GET_QUANTITY_USERS(state, unsubscribe) {
      state.unsubscribeGetQuantityUsers();
    },
    UPDATE_USER(state, userData) {
      state.actualUsersPage.splice(userData.indexActualPage, 1, userData.user);
      state.users.splice(userData.indexAllPage, 1, userData.user);
    },
    SET_USERS_LIST(state, users) {
      state.usersList = users
    },
    SET_ACTUAL_PAGE_USERS_LIST(state, page) {
      state.actualPageUsersList = page
    },
    SET_USERS_TO_UPDATE(state, data) {
      state.usersToUpdateList = data.users
      state.nextPageToken = data.nextPageToken
    },
    SET_UPDATE_COUNTER(state, uid) {
      state.counterUpdate++
      console.log(`${uid} Updated user Nº ${state.counterUpdate}`);
    },
    SET_DATE_RANGE_USERS(state, dateRangeUsers) {
      state.dateRangeUsers = dateRangeUsers
    },
  },
  actions: {
    GetUsersForMetricsAgeAndGender() {
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection("users").get()
          const users = []
          const countAges = {
            NoGender: 0,
          }
          const genders = {
            1: 'Females',
            2: 'Males'
          }

          for (let i = 0; i < querySnapshot.docs.length; i++) {
            let user = querySnapshot.docs[i].data();
            const age = moment().diff(user.birthday, 'years');
            user.age = age

            // console.log('user', user);
            // console.log('age:', age);
            users.push(user)
            console.log('documents user:', i + 1);
          }

          const orderedUsers = _.orderBy(users, ['age'], ['asc']);

          for (let i = 0; i < orderedUsers.length; i++) {
            let user = orderedUsers[i]
            const age = moment().diff(user.birthday, 'years');
            if (user.gender) {
              if (countAges[`${age}${genders[user.gender]}`]) {
                countAges[`${age}${genders[user.gender]}`]++
              } else {
                countAges[`${age}${genders[user.gender]}`] = 1
              }
            } else {
              countAges.NoGender = countAges.NoGender++
            }
            console.log('documents updated:', i + 1);
          }


          console.log(JSON.stringify(countAges))
          console.log('orderedUsers:', orderedUsers);
          resolve(users)
        } catch (error) {
          reject(error)
        }
      })

    },
    GetUsersForUpdateTopics() {
      return new Promise(async (resolve, reject) => {

        const querySnapshot = await db.collection("users").where("tokenNotification.active", "==", true).get()
        const users = []

        for (let i = 0; i < querySnapshot.docs.length; i++) {
          try {
            let user = querySnapshot.docs[i].data();
            const topicsList = []
            if (user.tokenNotification && user.tokenNotification.active && user.tokenNotification.token) {
              if (user.activeSubscription && user.activeSubscription.isPremium) {
                topicsList.push('PremiumUsers')
              } else {
                if (!topicsList.some(topic => topic === 'FreeUsers')) {
                  topicsList.push('FreeUsers')
                }
              }
              if (user.creationTime && user.creationTime.toDate() <= new Date('2021-12-07')) {
                topicsList.push('GrandFatheredUsers')
              } else {
                if (!topicsList.some(topic => topic === 'FreeUsers')) {
                  topicsList.push('FreeUsers')
                }
              }
              if (user.country && user.country.length === 2) {
                topicsList.push(`Country${user.country}Users`)
              }
              if (user.devicesInfo && user.devicesInfo.length > 0) {
                const lastDevice = user.devicesInfo[user.devicesInfo.length - 1]
                if (lastDevice.platform === 'android') {
                  topicsList.push('AndroidUsers')
                }
                if (lastDevice.platform === 'ios') {
                  topicsList.push('AppleUsers')
                }
              }
              if (user.gender) {
                if (user.gender === 1) {
                  topicsList.push('FemaleUsers')
                }
                if (user.gender === 2) {
                  topicsList.push('MaleUsers')
                }
              }
              if (user.challenge) {
                if (user.challenge === 1) {
                  topicsList.push('BurnGoalUsers')

                }
                if (user.challenge === 2) {
                  topicsList.push('ToneGoalUsers')
                }
                if (user.challenge === 3) {
                  topicsList.push('BuildGoalUsers')
                }

              }
              if (user.fitnessLevel) {
                if (user.fitnessLevel === 1) {
                  topicsList.push('EliteFitnessLevelUsers')
                }
                if (user.fitnessLevel === 2) {
                  topicsList.push('SeriousFitnessLevelUsers')
                }
                if (user.fitnessLevel === 3) {
                  topicsList.push('CasualFitnessLevelUsers')
                }
                if (user.fitnessLevel === 4) {
                  topicsList.push('StarterFitnessLevelUsers')
                }
              }

              users.push(user)
              console.log('topicsList', topicsList);
              if (topicsList.length > 0) {
                const res = await Vue.axios.post(requestEndPoint.addUserToManyTopics, { topicsList: topicsList, registrationTokens: [user.tokenNotification.token] });
                console.log('documents updated:', i + 1);
              }
            }
          } catch (error) {
            continue
          }


        }
        console.log('users:', users);
        // resolve(users)

      })

    },
    GetMetaDataUsers({ commit, getters }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post(requestEndPoint.retrieveAuthInfoUsers, { nextPageToken: getters.getNextPageToken });
          commit("SET_USERS_TO_UPDATE", res.data)
          console.log("Saved in array data!");
        } catch (error) {
          console.log(error)
        }
      })

    },
    GetDataTest({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection("subscriptionsGettedByUsers").doc('NHc7LvMMFSQSu6z1zbPw').get()
          const sub = querySnapshot.data()
          delete sub.typeSubscription
          delete sub.user
          delete sub.dataIAP.end_date
          delete sub.dataIAP.start_date
          delete sub.duration
          console.log(sub);
          console.log(JSON.stringify(sub))
          resolve(true)
        } catch (error) {
          reject(error)
        }
      })

    },

    GoUpdateMetadataUsers({ commit, getters }) {
      return new Promise(async (resolve, reject) => {
        try {
          // console.log(firebase.firestore().collection("users").doc(user.uid))
          for (let i = 0; i < getters.getUsersToUpdateList.length; i++) {
            const user = getters.getUsersToUpdateList[i];
            const validateUser = await firebase.firestore().collection("users").doc(user.uid).get()
            if (validateUser.exists) {
              await firebase.firestore().collection("users").doc(user.uid).update({
                email: user.email ? user.email : '',
                creationTime: new Date(user.metadata.creationTime),
              })
              commit("SET_UPDATE_COUNTER", user.uid)
            }
          }
        } catch (error) {
          console.log(error)
        }
      })

    },
    Login({ commit }, authData) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signInWithEmailAndPassword(authData.email, authData.password)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    DeleteAccount({ commit }, userId) {
      return new Promise(async (resolve, reject) => {
        const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/deleteAccount', { userId }, { headers: null });
        resolve(res.data);
      });
    },
    ReAuthenticateAdmin({ }, pass) {
      return new Promise((resolve, reject) => {
        const user = firebase.auth().currentUser;
        var credentials = firebase.auth.EmailAuthProvider.credential(
          user.email,
          pass
        );
        user.reauthenticateWithCredential(credentials)
          .then((res) => {
            console.log(res);
            resolve(res);
          })
          .catch((err) => {
            console.log('err', err);
            reject(err);
          });
      });
    },
    GetAdmin({ commit, dispatch }, userID) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("users")
          .doc(userID)
          .get()
          .then((res) => {
            let admin = res.data();
            if (admin && admin.adminLevel >= 1) {
              dispatch('GetAdminEmail', userID)
                .then((user) => {
                  commit("SET_ADMIN", { ...admin, email: user.email, photoURL: user.photoURL, metadata: user.metadata });
                  resolve(admin);
                })
                .catch((err) => {
                  reject(false)
                })
            } else {
              reject(false);
            }
          });
      });
    },
    getFreeUsersData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getFreeUsersInfo', { premium: false }, { headers: null });

          // let rows = [];

          // function sliceIntoChunks(arr, chunkSize) {
          //   const res = [];
          //   for (let i = 0; i < arr.length; i += chunkSize) {
          //     const chunk = arr.slice(i, i + chunkSize);
          //     res.push(chunk);
          //   }
          //   return res;
          // }

          // const parts = sliceIntoChunks(res.data, 100);

          // for (let index = 0; index < parts.length; index++) {
          //   const element = parts[index];
          //   const elementRows = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getSubHistoryFromUsers', { users: element }, { headers: null });
          //   const newRows = elementRows.data;
          //   rows = rows.concat(newRows);
          // }

          // for (let index = 0; index < rows.length; index++) {
          //   rows[index].subsHistory = JSON.stringify(rows[index].subsHistory);
          // }

          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getPremiumUsersData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getFreeUsersInfo', { premium: true }, { headers: null });

          // let rows = [];

          // function sliceIntoChunks(arr, chunkSize) {
          //   const res = [];
          //   for (let i = 0; i < arr.length; i += chunkSize) {
          //     const chunk = arr.slice(i, i + chunkSize);
          //     res.push(chunk);
          //   }
          //   return res;
          // }

          // const parts = sliceIntoChunks(res.data, 100);

          // for (let index = 0; index < parts.length; index++) {
          //   const element = parts[index];
          //   const elementRows = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getSubHistoryFromUsers', { users: element }, { headers: null });
          //   const newRows = elementRows.data;
          //   rows = rows.concat(newRows);
          // }

          // for (let index = 0; index < rows.length; index++) {
          //   rows[index].subsHistory = JSON.stringify(rows[index].subsHistory);
          // }

          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    GetAllUsersListForCSV() {
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection("users").get()
          const usersList = []
          querySnapshot.forEach(async (doc) => {
            let user = doc.data()
            const { uid, firstName, lastName, displayName, email, creationTime, height, weight, gender, instructor, country } = user
            const objUser = {
              uid,
              firstName,
              lastName,
              displayName,
              email,
              creationTime: moment(new Date(creationTime.seconds * 1000)).format("DD/MM/YYYY"),
              heightInCms: parseInt(height),
              weightInKgs: parseInt(weight),
              gender: gender === 1 ? 'Female' : gender === 2 ? 'Male' : '',
              instructor,
              country
            }
            usersList.push(objUser)
          })
          resolve(usersList)
        } catch (error) {
          reject(error)
        }
      });

    },
    GetUserSubcriptionsStats() {
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection("users").get()
          const GR_DATE = new Date('2021-12-07')
          const premium = []
          const grandFathered = []
          const free = []
          let totalUsers = 0
          for (let i = 0; i < querySnapshot.docs.length; i++) {
            let user = querySnapshot.docs[i].data()
            totalUsers++
            if (user && user.activeSubscription && user.activeSubscription.isPremium) {
              premium.push(querySnapshot.docs[i].id)
            } else if (user.creationTime && user.creationTime.toDate() <= GR_DATE) {
              grandFathered.push(querySnapshot.docs[i].id)
            } else {
              free.push(querySnapshot.docs[i].id)
            }
          }
          resolve({
            totalUsers,
            premium: premium.length,
            grandFathered: grandFathered.length,
            free: free.length,
          })

        } catch (error) {
          reject(error)
        }

      })

    },
    GetUsersByDateRange({ }, dateRange) {
      return new Promise(async (resolve, reject) => {
        try {
          const querySnapshot = await db.collection("users").where("creationTime", ">=", new Date(dateRange.startDate)).where("creationTime", "<=", new Date(dateRange.endDate)).get()
          const usersInRangeDate = []
          for (let i = 0; i < querySnapshot.docs.length; i++) {
            let user = querySnapshot.docs[i].data()
            usersInRangeDate.push(user)
          }
          resolve(usersInRangeDate.length)
        } catch (error) {
          reject(error)
        }
      })
    },
    GetUserWithSubCollections({ dispatch }, uid) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then((res) => {
            let user = res.data();
            dispatch('GetAdminEmail', uid)
              .then((payload) => {
                // dispatch("GetUserSubCollections", user.uid)
                // .then((saves) => {
                console.log(user);
                resolve({ ...user, email: payload.email, photoURL: payload.photoURL, metadata: payload.metadata });
                // resolve({ ...user, email:payload.email, photoURL:payload.photoURL,  savesUser:saves, metadata:payload.metadata });
                // })
                // .catch((errSubCollections) => {
                //   reject(false)
                // })
              })
              .catch((errEmail) => {
                reject(false)
              })
          })
          .catch((err) => {
            reject(err)
          })
      });

    },
    GetUser({ dispatch }, uid) {
      return new Promise((resolve, reject) => {
        firebase
          .firestore()
          .collection("users")
          .doc(uid)
          .get()
          .then((res) => {
            let user = res.data();
            dispatch('GetAdminEmail', uid)
              .then((payload) => {
                resolve({ ...user, email: payload.email, photoURL: payload.photoURL, metadata: payload.metadata });
              })
              .catch((errEmail) => {
                reject(false)
              })
          })
          .catch((err) => {
            reject(err)
          })
      });

    },
    GetAdminEmail({ }, uid) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.getAdminEmail, { uid: uid })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    },
    isAuthenticated() {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            resolve(user.uid);
          } else {
            reject(false);
          }
        });
      });
    },
    Logout({ commit }, authData) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            commit("LOGOUT");
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      });
    },
    GetAllUsers({ commit, state }, sorts) {
      return new Promise((resolve, reject) => {
        let existsInUsers = sorts.page * sorts.itemsPerPage > state.users.length
        Vue.axios
          .post(requestEndPoint.getAllUsers, sorts)
          .then((response) => {
            let data = {
              users: response.data.res,
              sorts: sorts
            }
            if (existsInUsers) {
              commit("SET_USERS", data);
              commit("SET_ACTUAL_USERS_PAGE", sorts);
            } else {
              commit("SET_ACTUAL_USERS_PAGE", sorts);
            }
            resolve(response.data);
          })
          .catch((err) => {
            console.error(err)
            reject(err);
          });
      });
    },
    GetPageActual({ commit }, sorts) {
      return new Promise((resolve, reject) => {
        commit("SET_ACTUAL_USERS_PAGE", sorts);
        resolve();
      })

    },
    ResetValues({ commit }) {
      return new Promise((resolve, reject) => {
        commit("RESET_VALUES_USERS");
        resolve();
      })

    },
    GetQuantityUsers({ commit }) {
      return new Promise((resolve, reject) => {
        let developmentDocumentId = 'hJ4BLOnqCwwCOi6buSx5'
        let unsubscribe = firebase.firestore()
          .collection("quantityUsers")
          .doc(developmentDocumentId)
          .onSnapshot((res) => {
            let quantity = res.data();
            commit("SET_QUANTITY_USERS", quantity.total);
            resolve(quantity.total);

          }, (error) => { reject(error); })
        commit('SET_UNSUBSCRIBE_GET_QUANTITY_USERS', unsubscribe);
      });
    },
    GoUnsubscribeGetQuantityUsers({ commit }) {
      commit('GO_UNSUBSCRIBE_GET_QUANTITY_USERS');
    },
    SearchByEmail({ commit }, email) {
      return new Promise((resolve, reject) => {
        commit('RESET_VALUES_USERS');
        let sorts = {
          page: 1,
          itemsPerPage: 1,
        }
        Vue.axios
          .post(requestEndPoint.searchByEmail, email)
          .then((response) => {
            if (response.data) {
              let data = {
                users: [response.data],
              }
              commit("SET_USERS", data);
              commit("SET_ACTUAL_USERS_PAGE", sorts);
              resolve(response.data);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            if (err.data) {
              let data = {
                users: [err.data],
              }
              commit("SET_USERS", data);
              commit("SET_ACTUAL_USERS_PAGE", sorts);
            } else {
              commit('RESET_VALUES_USERS');
            }
            resolve(err);
          });
      })
    },
    DisableUser({ commit }, disableData) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.disableUser, disableData, { headers: null })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    UpdateUser({ commit }, updateData) {
      commit("UPDATE_USER", updateData);
    },
    SendPasswordResetEmail({ commit }, emailData) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.sendResetPasswordEmail, emailData, { headers: null })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    SendVerificationEmail({ commit }, emailData) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.sendVerificationEmail, emailData, { headers: null })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    ChangeRoleUser({ }, data) {
      return new Promise((resolve, reject) => {
        firebase.firestore()
          .collection("users")
          .doc(data.uid).update({
            adminLevel: data.adminLevel != '' ? data.adminLevel : firebase.firestore.FieldValue.delete(),
            allowedRoutes: data.adminLevel != '' ? data.allowedRoutes : firebase.firestore.FieldValue.delete()
          }).then((res) => {
            resolve(res)
          }).catch((err) => {
            reject(err);
          });

      })
    },
    UpdateViewsAllowed({ }, data) {
      return new Promise((resolve, reject) => {
        firebase.firestore()
          .collection("users")
          .doc(data.uid).update({
            allowedRoutes: data.allowedRoutes
          }).then((res) => {
            resolve(data.allowedRoutes)
          }).catch((err) => {
            reject(err);
          });

      })
    },
    GetUserSubCollections({ }, uid) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(requestEndPoint.getUserSubCollections, { uid: uid }, { headers: null })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    GenerateDynamicLink({ }, payload) {
      return new Promise((resolve, reject) => {
        const urlRef = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`
        const defaultImageLink = 'https://cdn.shopify.com/s/files/1/0252/3860/1775/files/maxpro_favicon_x50@2x.png?v=1576188561'
        let baseLink = 'https://maxpro.page.link'
        let objectLink = {
          dynamicLinkInfo: {
            domainUriPrefix: `${baseLink}`,
            link: `https://maxprofitness.com/${payload.routeName}/${payload.id}`,
            androidInfo: {
              androidPackageName: "com.itza.maxpro"
            },
            iosInfo: {
              iosBundleId: "com.itza.maxpro"
            },
            socialMetaTagInfo: {
              socialTitle: payload.title,
              socialDescription: 'MaxPro Fitness APP',
              socialImageLink: payload.img || defaultImageLink
            }
          },
          suffix: {
            option: "SHORT"
          },
        }
        Vue.axios
          .post(urlRef, objectLink)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });

    },
    GetPaginatedUsersList({ commit, getters }, optionsUsers) {
      return new Promise(async (resolve, reject) => {
        try {
          let querySnapshot = db.collection("users").orderBy('uid', 'asc')

          if (optionsUsers.search.values.firstName !== "") {
            querySnapshot = querySnapshot.where("firstName", "==", optionsUsers.search.values.firstName)
          }
          if (optionsUsers.search.values.lastName !== "") {
            querySnapshot = querySnapshot.where("lastName", "==", optionsUsers.search.values.lastName)
          }
          if (optionsUsers.search.values.email !== "") {
            querySnapshot = querySnapshot.where("email", "==", optionsUsers.search.values.email)
          }

          if (optionsUsers.page === 1) {
            querySnapshot = querySnapshot.limit(optionsUsers.itemsPerPage)
          } else if (optionsUsers.page > getters.getActualPageUsersList) {
            let lastValue = getters.getUsersList[getters.getUsersList.length - 1].uid
            querySnapshot = querySnapshot.startAfter(lastValue).limit(optionsUsers.itemsPerPage)
          } else if (optionsUsers.page < getters.getActualPageUsersList) {
            let firstValue = getters.getUsersList[0].uid
            querySnapshot = querySnapshot.endBefore(firstValue).limitToLast(optionsUsers.itemsPerPage)
          }
          // let querySnapshot = null

          // if(optionsUsers.)
          querySnapshot = await querySnapshot.get()
          const users = []

          for (let i = 0; i < querySnapshot.docs.length; i++) {
            let user = querySnapshot.docs[i].data();
            users.push(user);
          }


          commit("SET_ACTUAL_PAGE_USERS_LIST", optionsUsers.page);

          // console.log(users);
          commit("SET_USERS_LIST", users);
          resolve(users)
        } catch (error) {
          console.log(error)
          reject(error)
        }

      })

    },
    ChangeStateCoach({ }, user) {
      return new Promise(async (resolve, reject) => {
        try {
          await db.collection("users").doc(user.uid).update({
            isCoach: user.isCoach
          })
          resolve(user.uid)
        } catch (error) {
          console.log(error)
          reject(error)
        }
      })
    }
  },
  getters: {
    isAuthenticated(state) {
      if (Object.keys(state.admin).length !== 0) {
        return true;
      } else {
        return false;
      }
    },
    getAdmin(state) {
      return state.admin;
    },
    getAllUsers(state) {
      return state.users;
    },
    getActualUsersPage(state) {
      return state.actualUsersPage;
    },
    getQuantityUsers(state) {
      return state.quantityUsers
    },
    getUsersList(state) {
      return state.usersList
    },
    getActualPageUsersList(state) {
      return state.actualPageUsersList
    },
    getUsersToUpdateList(state) {
      return state.usersToUpdateList
    },
    getNextPageToken(state) {
      return state.nextPageToken
    },
    getDateRangesUsers(state) {
      return state.dateRangeUsers
    },
  },
};
