import firebase from "firebase";
import Vue from "vue";
const db = firebase.firestore();
import Axios from "axios";
import VueAxios from "vue-axios";
import axios from "axios";
import moment from 'moment';

let unsubEvents = undefined;
let unsubUsers = undefined;

Vue.use(VueAxios, Axios);

const getUserData = async (userId) => {
  try {
    const user = await db.collection('users').doc(userId).get();
    if (user.exists) {
      return { id: user.id, ...user.data() };
    }

    return userId;
  } catch (error) {
    console.error(error);
  }
}

const stateCollections = {
  gender: 'byGender',
  fitnessLevel: 'byLevel',
  challenge: 'byChallenge',
}

export default {
  state: {
    byGender: {},
    byLevel: {},
    byChallenge: {},
  },
  mutations: {
    SET_COUNTERS(state, counters) {
      state[stateCollections[counters.type]] = counters.data
    }
  },
  actions: {
    addLiveUsersListener({ commit }, onUsersChange) {
      const now = moment(new Date()).subtract(5, 'minutes').toDate();
      unsubUsers = db.collection("users").where('lastLogin', '>=', now).limit(100).onSnapshot(async (querySnapshot) => {
        try {
          const docs = querySnapshot.docs.map((e) => ({ id: e.id, ...e.data() }));
          onUsersChange(docs);
        } catch (error) {
          console.error(error);
        }
      });
    },
    removeLiveUsersListener({ commit }) {
      unsubUsers();
    },
    addInAppEventsListener({ commit }, onEventsChange) {
      unsubEvents = db.collection("inAppEvents").where('visible', '==', true).orderBy('createdAt', 'desc').limit(100).onSnapshot(async (querySnapshot) => {
        try {
          const docs = await Promise.all(querySnapshot.docs.map(async (e) => {
            const eventData = { id: e.id, ...e.data() };
            if (eventData.user && eventData.user.length > 0) {
              const userData = await getUserData(eventData.user);
              eventData.user = userData;
            }
            return eventData;
          }))
          onEventsChange(docs);
        } catch (error) {
          console.error(error);
        }
      });
    },
    removeInAppEventsListener({ commit }) {
      unsubEvents();
    },
    async getCurrentPremiums({ commit }, comparedDate) {
      try {

        const todayStart = moment().startOf('day').toDate();
        const todayEnd = moment().endOf('day').toDate();
        const today = await db.collection('premiumsNumbers').where('createdAt', '<=', todayEnd).where('createdAt', '>=', todayStart).get();
        const todayItem = today.docs[0];
        const todayData = { ...todayItem.data(), id: todayItem.id };

        let comparedStart;
        let comparedEnd;

        if (comparedDate) {
          comparedStart = moment(comparedDate).startOf('day').toDate();
          comparedEnd = moment(comparedDate).endOf('day').toDate();
        } else {
          comparedStart = moment().subtract(1, 'day').startOf('day').toDate();
          comparedEnd = moment().subtract(1, 'day').endOf('day').toDate();
        }

        const compared = await db.collection('premiumsNumbers').where('createdAt', '<=', comparedEnd).where('createdAt', '>=', comparedStart).get();
        const comparedItem = compared.docs[0];
        const comparedData = { ...comparedItem.data(), id: comparedItem.id };

        const difference = todayData.current - comparedData.current;
        const differencePercent = (100 / comparedData.current) * difference;

        return {
          today: todayData,
          compared: comparedData,
          comparedDate: comparedStart,
          difference,
          differencePercent
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNewPremiums({ commit }, dateRange) {
      try {
        // const today = moment().startOf('day');
        // const before = today.clone().subtract(15, 'days').toDate();
        // const after = today.clone().add(15, 'days').endOf('day').toDate();

        let today;
        let before;

        if (dateRange) {
          today = moment(dateRange.endDate).endOf('day').toDate();
          before = moment(dateRange.startDate).startOf('day').toDate();
        } else {
          today = moment().toDate();
          before = moment().subtract(30, 'days').toDate();
        }

        const result = await db.collection('premiumsNumbers').where('createdAt', '<=', today).where('createdAt', '>=', before).get();

        const xValues = [];
        const yValues = [];
        const csvValues = [];
        result.docs.map(e => {
          const itemData = { ...e.data(), id: e.id };
          const value = itemData.current;
          const label = itemData.createdAt.toDate().toLocaleDateString();
          csvValues.push({ Date: label, PremiumUsers: value });
          xValues.push(label);
          yValues.push(value);
        });

        return {
          yValues,
          xValues,
          dateRange: {
            startDate: before,
            endDate: today
          },
          csvValues
        }
      } catch (error) {
        return [];
      }
    },
    GetCounterAnalitycs({ commit }, type) {
      return new Promise((resolve, reject) => {
        db.collection("countersAnalytics").doc(type).get()
          .then((res) => {
            let counters = res.data()
            commit('SET_COUNTERS', { data: counters, type: type })
            resolve(counters)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUserResults({ commit }, userId) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUserResults', { userId }, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getWorkoutsInfo({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getWorkoutsInfo', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getTopTen({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getTopTen', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getPresetsInfo({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getPresetsInfo', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getExercisesData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getNewWorkoutsData', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getWorkoutsData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          // const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersWorkoutsInfo', null, { headers: null });

          // const db = admin.firestore();
          const usersCollection = db.collection('users');

          const users = await usersCollection.where('firstTimeInApp', '==', true).get();
          const usersData = users.docs.map(u => u.id);

          let rows = [];

          function sliceIntoChunks(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
              const chunk = arr.slice(i, i + chunkSize);
              res.push(chunk);
            }
            return res;
          }

          const parts = sliceIntoChunks(usersData, 100);
          // const parts = [['9nw0Q8BUEIczpHznvs3mi0DRFNC3']]

          for (let index = 0; index < parts.length; index++) {

            const element = parts[index];
            // console.log(element.length, element.includes(undefined));

            const elementRows = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersWorkoutsInfo', { users: element }, { headers: null });

            const newRows = elementRows.data;
            // console.log('rows: ', newRows.length);

            rows = rows.concat(newRows);
            console.log(((usersData / parts.length) * (index + 1)).toFixed(1));
          }
          resolve(rows);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getProgramUsers({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getProgramUsers', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getFreeUsersData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getFreeUsersInfo', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getUsersData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersData', null, { headers: null });
          console.log(res.data.length, res.data[res.data.length - 1].id);
          const last = res.data[res.data.length - 1];
          const res2 = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersData', { last: last.uid }, { headers: null });
          console.log(res2.data.length, res2.data[res.data.length - 1].id);
          resolve([...res.data, ...res2.data]);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    extractSubInfo({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const times = 6; //x10,000 times to accomplish total of users
          let last = undefined;

          for (let index = 0; index < times; index++) {
            console.log('LAST: ', last);

            if (last) {
              const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/extractSubInfo', { last }, { headers: null });
              last = res.data;
            } else {
              const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/extractSubInfo', null, { headers: null });
              last = res.data;
            }
          }

          resolve('ok');
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    fixPosts({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/fixPostBeforeAlgolia', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getJanuaryPosts({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getPostsByRange', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
  },
  getters: {
    getCounterByGender(state) {
      return state.byGender
    },
    getCounterByLevel(state) {
      return state.byLevel
    },
    getCounterByChallenge(state) {
      return state.byChallenge
    },
  },
};
