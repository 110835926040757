<template>
  <v-dialog
    v-model="show"
    width="80%"
    class="rounded-xl"
    @click:outside="close"
  >
    <v-card class="pa-5">
      <v-card-actions class="modal-header">
        Premium Users Chart
        <v-btn icon color="red" @click="close">
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="content-container">
        <div class="calendar-container">
          <v-date-picker
            v-model="dates"
            :value="dates"
            range
            :min-date="new Date('03-10-2025')"
            :max-date="new Date()"
            :show-current="true"
            first-day-of-week="1"
            :allowed-dates="allowedDates"
            @change="onDateChange"
          ></v-date-picker>
          <vue-json-to-csv
            class="download-icon-container"
            :csv-title="`premiumNumbers_${chartData?.dateRange?.startDate?.toLocaleDateString()}-${chartData?.dateRange?.endDate?.toLocaleDateString()}`"
            :json-data="chartData?.csvValues ?? []"
            ><v-icon class="download-icon-2" color="primary"
              >mdi-download-box-outline</v-icon
            >Download CSV
          </vue-json-to-csv>
        </div>
        <div class="big-chart">
          <canvas id="bigChart" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Chart from "chart.js";
import moment from "moment";
import { nextDay } from "date-fns";

export default {
  components: { DateRangePicker },
  name: "premium-chart-modal",
  model: {},
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    chartData: {
      type: Object,
      required: true,
      default: [],
    },
    onUpdate: {
      type: Function,
      required: true,
      default: () => {},
    },
    close: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      dates: [
        moment().subtract(30, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      currentChart: undefined,
    };
  },
  mounted() {},
  methods: {
    getOrderedDates(dates) {
      const orderedDates = dates.sort((a, b) => new Date(a) - new Date(b));
      const startDate = orderedDates[0];
      const endDate = orderedDates[1];
      return { startDate, endDate };
    },
    getDatesRange(dates) {
      return [
        moment(dates.startDate).format("YYYY-MM-DD"),
        moment(dates.endDate).format("YYYY-MM-DD"),
      ];
    },
    renderChart(chartValues) {
      setTimeout(() => {
        let premiumChart = document.getElementById("bigChart");
        if (chartValues && premiumChart) {
          this.dates = this.getDatesRange(chartValues.dateRange);
          if (this.currentChart) {
            this.currentChart.destroy();
          }
          this.currentChart = new Chart(premiumChart, {
            type: "line",
            data: {
              labels: chartValues.xValues,
              datasets: [
                {
                  fill: true,
                  label: "Premium users",
                  data: chartValues.yValues,
                  backgroundColor: "rgba(236, 92, 0, 0.2)",
                  borderColor: "rgba(236, 92, 0, 1)",
                  borderWidth: 1,
                  tension: 0.1,
                },
              ],
            },
            options: {
              responsive: true,
              legend: { display: false },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontSize: 9,
                      callback: function (val) {
                        return Number(val).toFixed(0);
                      },
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      autoSkip: false,
                      maxRotation: 90,
                      minRotation: 90,
                      callback: function (val) {
                        return val;
                      },
                    },
                  },
                ],
              },
            },
          });
        }
      }, 100);
    },
    allowedDates(date) {
      // Convert the date strings to Date objects for comparison
      const currentDate = new Date(date);
      const min = new Date("2025-03-10");
      const max = new Date();

      // Return true only for dates within the range (inclusive)
      return currentDate >= min && currentDate <= max;
    },
    onDateChange(dates) {
      const orderedDates = this.getOrderedDates(dates);
      this.onUpdate(orderedDates, true);
    },
  },
  watch: {
    chartData: {
      handler: function (newValue, oldValue) {
        this.renderChart(newValue);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.modal-header {
  justify-content: space-between;
  font-size: 18px;
  color: #3a3a3a;
  font-weight: bold;
}
.big-chart {
  flex: 1;
}
.content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.download-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-weight: bold;
  color: #3a3a3a;
  transition: all 0.2s ease-in-out;
  opacity: 0.8;
}
.download-icon-2 {
  font-size: 32px;
  margin-right: 4px;
}
.download-icon-container:hover {
  cursor: pointer;
  transform: scale(1.1);
  opacity: 1;
}
</style>
