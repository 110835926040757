<template>
  <v-dialog v-model="show" width="800" class="rounded-xl">
    <v-card class="pa-5">
      <v-card-actions class="modal-header">
        Error Message
        <v-btn icon color="red" @click="close">
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        {{ error }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "error-modal",
  model: {},
  props: {
    show: undefined,
    error: undefined,
    close: undefined,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.modal-header {
  justify-content: space-between;
  font-size: 18px;
  color: #3a3a3a;
  font-weight: bold;
}
</style>
