<template>
  <v-container fluid class="px-10">
    <p class="text-h6 text--secondary font-weight-bold">Dashboard</p>
    <div class="row">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="premium-numbers-container">
          <div class="premium-numbers-header">
            <div class="chart-icon-container">
              <img
                :src="require('@/assets/chart_white.svg')"
                class="chart-icon"
              />
            </div>
            <span class="premium-numbers-title">Total Premium Users</span>
          </div>
          <div class="premium-numbers-data-container">
            <div>
              <div>Today's Premium users:</div>
              <div>
                Compared with
                <date-range-picker
                  v-model="premiumNumbersDate"
                  single-date-picker="single"
                  :auto-apply="true"
                  ref="picker"
                  :min-date="new Date('03-10-2025')"
                  :max-date="new Date()"
                  @update="getPremiumNumbers"
                  control-container-class="test"
                  :ranges="false"
                >
                  <template v-slot:input="picker">
                    <div class="test">
                      {{ getSelectedDate(picker)
                      }}<v-icon
                        style="margin-top: -3px; margin-left: 4px"
                        color="primary"
                        >mdi-calendar-edit</v-icon
                      >
                    </div>
                  </template>
                </date-range-picker>
                <!-- {{ comparisionData.comparedDate.toLocaleDateString() }}: -->
              </div>
            </div>
            <div style="display: flex; gap: 16px">
              <div>
                <div>{{ comparisionData.today.current }}</div>
                <div>{{ comparisionData.compared.current }}</div>
              </div>
              <div>
                <div
                  style="font-weight: bold"
                  :style="
                    comparisionData.difference > 0
                      ? `color:green`
                      : comparisionData.difference < 0
                      ? `color:red`
                      : ``
                  "
                >
                  <img
                    :src="require('@/assets/subs_up.svg')"
                    class="triangle"
                    v-if="comparisionData.difference > 0"
                  />
                  <img
                    :src="require('@/assets/subs_down.svg')"
                    class="triangle triangle-down"
                    v-if="comparisionData.difference < 0"
                  />
                  {{ comparisionData.difference }} |
                  {{ comparisionData.differencePercent.toFixed(2) }}%
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="premium-chart-container">
          <div class="premium-title-container">
            <span class="premium-chart-title">Premium Subscribers</span>
            <date-range-picker
              v-model="premiumChartRange"
              single-date-picker="range"
              :auto-apply="true"
              ref="picker"
              :min-date="new Date('03-10-2025')"
              :max-date="new Date()"
              @update="getNewPremiumsChart"
              control-container-class="test"
              :ranges="false"
            >
              <template v-slot:input="">
                <div class="premium-date-range">
                  {{ premiumChartRange.startDate.toLocaleDateString() }} -
                  {{ premiumChartRange.endDate.toLocaleDateString() }}
                </div>
              </template>
            </date-range-picker>
            <vue-json-to-csv
              class="download-icon-container"
              :csv-title="`premiumNumbers_${premiumChartRange.startDate.toLocaleDateString()}-${premiumChartRange.endDate.toLocaleDateString()}`"
              :json-data="currentChartData.csvValues"
              ><v-icon class="download-icon" color="primary"
                >mdi-download-box-outline</v-icon
              >Download CSV
            </vue-json-to-csv>
          </div>
          <canvas class="premium-chart" id="newPremiums"></canvas>
          <img
            :src="require('@/assets/open.svg')"
            alt=""
            class="open-chart-button"
            @click="openChartModal"
          />
        </v-card>
      </v-col>
    </div>
    <div class="row">
      <div class="col">
        <v-data-table
          :headers="eventsHeaders"
          :items="inAppEvents"
          fixed-header
          hide-default-footer
          class="elevation-4"
          :items-per-page="100"
        >
          <template v-slot:[`item.text`]="{ item }">
            <event-row
              :openErrorModal="openErrorModal"
              :item="item"
              :openPostModal="openPostModal"
            />
          </template>
        </v-data-table>
      </div>
      <div class="col">
        <v-data-table
          :headers="liveUsersHeaders"
          :items="liveUsers"
          fixed-header
          hide-default-footer
          class="elevation-4"
          :items-per-page="100"
        >
          <template v-slot:[`item.nickname`]="{ item }">
            <span class="live-user"
              ><div class="live-dot" />
              {{ item.nickname }}</span
            >
          </template>
        </v-data-table>
      </div>
    </div>
    <error-modal
      :show="showErrorModal"
      :error="errorMessage"
      :close="closeErrorModal"
    />
    <post-detail-modal
      :show="showPostDetail"
      :item="selectedPost"
      :close="closePostModal"
    />
    <premium-chart-modal
      :show="showChartModal"
      :chartData="chartData"
      :close="closeChartModal"
      :onUpdate="getNewPremiumsChart"
    />
  </v-container>
</template>

<script>
import EventRow from "@/components/admin/events/EventRow.vue";
import ErrorModal from "@/components/admin/events/ErrorModal.vue";
import PostDetailModal from "@/components/admin/posts/PostDetailModal.vue";
import Chart from "chart.js";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import premiumChartModal from "../../../components/admin/dashboard/premiumChartModal.vue";

export default {
  name: "Dashboard",
  components: {
    EventRow,
    ErrorModal,
    PostDetailModal,
    DateRangePicker,
    premiumChartModal,
  },
  data: () => ({
    inAppEvents: [],
    liveUsers: [],
    eventsHeaders: [
      {
        text: "In App Events",
        align: "start",
        value: "text",
        sortable: false,
      },
    ],
    liveUsersHeaders: [
      {
        text: "Live Users",
        align: "start",
        value: "nickname",
        sortable: false,
      },
    ],
    showErrorModal: false,
    errorMessage: "",
    showPostDetail: false,
    selectedPost: "",
    comparisionData: {
      today: { current: 0 },
      compared: { current: 0 },
      difference: 0,
      differencePercent: 0,
      comparedDate: new Date(),
    },
    premiumNumbersDate: {
      startDate: moment().subtract(1, "day").toDate(),
      endDate: new Date(),
    },
    premiumChartRange: {
      startDate: moment().subtract(30, "days").toDate(),
      endDate: new Date(),
    },
    showChartModal: false,
    chartData: { xValues: [], yValues: [], min: 0, max: 0 },
    currentChartData: {
      xValues: [],
      yValues: [],
      min: 0,
      max: 0,
      csvValues: [],
    },
    chart: undefined,
  }),
  created() {
    this.getInAppEvents();
    this.getLiveUsers();
    this.getNewPremiumsChart(undefined);
    this.getPremiumNumbers();
  },
  methods: {
    onEventsChange(newDocs) {
      this.inAppEvents = newDocs;
    },
    onLiveUsersChange(newDocs) {
      this.liveUsers = newDocs;
    },
    getInAppEvents() {
      this.$store.dispatch("addInAppEventsListener", this.onEventsChange);
    },
    getLiveUsers() {
      this.$store.dispatch("addLiveUsersListener", this.onLiveUsersChange);
    },
    openErrorModal(item) {
      this.errorMessage = item.error;
      this.showErrorModal = true;
    },
    closeErrorModal() {
      this.errorMessage = "";
      this.showErrorModal = false;
    },
    openPostModal(item) {
      this.selectedPost = item.postId;
      this.showPostDetail = true;
    },
    closePostModal() {
      this.selectedPost = undefined;
      this.showPostDetail = false;
    },
    async getPremiumNumbers(comparedDate) {
      if (comparedDate?.startDate) {
        this.premiumNumbersDate = comparedDate;
      }
      this.$store
        .dispatch("getCurrentPremiums", comparedDate?.startDate)
        .then((res) => {
          this.comparisionData = res;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getNewPremiumsChart(dateRange, fromModal) {
      this.$store
        .dispatch("getNewPremiums", dateRange)
        .then((res) => {
          if (this.chart) {
            this.chart.destroy();
          }
          this.currentChartData = res;
          this.premiumChartRange = res.dateRange;
          if (fromModal) {
            this.chartData = res;
          }
          let premiumChart = document.getElementById("newPremiums");

          this.chart = new Chart(premiumChart, {
            type: "line",
            data: {
              labels: res.xValues,
              datasets: [
                {
                  fill: true,
                  label: "Premium users",
                  data: res.yValues,
                  backgroundColor: "rgba(236, 92, 0, 0.2)",
                  borderColor: "rgba(236, 92, 0, 1)",
                  borderWidth: 1,
                  tension: 0.1,
                },
              ],
            },
            options: {
              legend: { display: false },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontSize: 9,
                      callback: function (val) {
                        return Number(val).toFixed(0);
                      },
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      autoSkip: false,
                      maxRotation: 90,
                      minRotation: 90,
                      callback: function (val) {
                        return "";
                      },
                    },
                  },
                ],
              },
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSelectedDate(range) {
      if (range.startDate) {
        return range.startDate.toLocaleDateString();
      }
      return new Date().toLocaleDateString();
    },
    openChartModal() {
      this.chartData = { ...this.currentChartData };
      this.showChartModal = true;
    },
    closeChartModal() {
      this.showChartModal = false;
      this.chartData = {
        xValues: [],
        yValues: [],
        min: 0,
        max: 0,
        dateRange: this.premiumChartRange,
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
.live-dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: green;
}
.live-user {
  display: flex;
  align-items: center;
  gap: 10px;
}
.premium-chart-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0px 16px;
  justify-content: space-between;
  height: 160px;
}
.premium-date-range {
  font-weight: bold;
  font-size: 12px;
  color: #3a3a3a;
  margin-bottom: 16px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #3a3a3a;
  border-radius: 8px;
  padding: 4px 8px;
  margin-top: 8px;
}
.premium-date-range:hover {
  cursor: pointer;
}
.premium-title-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.premium-chart-title {
  font-weight: bold;
  font-size: 14px;
  color: #3a3a3a;
  text-align: center;
}
.premium-chart {
  max-width: 60%;
  max-height: 90%;
}
.premium-numbers-container {
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  justify-content: space-between;
  height: 160px;
}
.premium-numbers-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.premium-numbers-title {
  font-weight: bold;
  font-size: 18px;
  color: #3a3a3a;
  text-align: left;
}
.triangle {
  height: 10px;
  width: 10px;
}
.chart-icon-container {
  background-color: #ed702d;
  height: 64px;
  width: 64px;
  border-radius: 32px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-icon {
  height: 40px;
  width: 40px;
}
.premium-numbers-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.test {
  color: #3a3a3a;
  font-weight: bold;
}
.test:hover {
  cursor: pointer;
}
.open-chart-button {
  height: 16px;
  width: 16px;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: all 0.2s ease-in-out;
  opacity: 0.5;
}
.open-chart-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  opacity: 1;
}
.triangle-down {
  transform: rotate(180deg);
}
.download-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-weight: bold;
  color: #3a3a3a;
  transition: all 0.2s ease-in-out;
  opacity: 0.8;
  font-size: 14px;
}
.download-icon {
  font-size: 24px;
  margin-right: 4px;
  margin-bottom: 2px;
}
.download-icon-container:hover {
  cursor: pointer;
  transform: scale(1.03);
  opacity: 1;
}
</style>