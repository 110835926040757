<template>
  <v-dialog
    v-model="show"
    width="500"
    class="rounded-xl"
    @click:outside="close"
  >
    <v-card class="pa-5">
      <v-card-actions class="modal-header">
        Post
        <v-btn icon color="red" @click="close">
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <div v-if="loading" class="loading">
        <v-progress-circular size="64" width="4" color="red" indeterminate />
      </div>
      <post-detail v-else :item="postData" />
    </v-card>
  </v-dialog>
</template>

<script>
import PostDetail from "./PostDetail.vue";
export default {
  components: { PostDetail },
  name: "post-detail-modal",
  model: {},
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    item: {
      type: String,
      required: true,
      default: "",
    },
    close: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      postData: undefined,
      loading: false,
    };
  },
  methods: {
    async getPost(postId) {
      this.loading = true;
      if (postId && postId.length > 0) {
        const postData = await this.$store.dispatch("getPostData", postId);
        console.log(JSON.stringify(postData));

        this.postData = postData;
      }
      this.loading = false;
    },
  },
  watch: {
    item: {
      handler: function (newValue, oldValue) {
        this.getPost(newValue);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.modal-header {
  justify-content: space-between;
  font-size: 18px;
  color: #3a3a3a;
  font-weight: bold;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
</style>
