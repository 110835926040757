import Home from "@/views/home/Home.vue";
import UserList from "@/views/admin/user-list/index.vue";
import User from "@/views/admin/user-list/user/index.vue";
import Dashboard from "@/views/admin/dashboardv2/index.vue";
import PushNotifications from "@/views/admin/push-notifications/index.vue";
import PushNotificationsCreate from "@/views/admin/push-notifications/create/index.vue";
import PushNotificationsEdit from "@/views/admin/push-notifications/edit/index.vue";
import PushNotificationsSend from "@/views/admin/push-notifications/send/index.vue";
import PushNotificationsByGroupSend from "@/views/admin/push-notifications-by-group/send/index.vue";
import PushNotificationsByGroupList from "@/views/admin/push-notifications-by-group/index.vue";
import ProfessionalWorkout from "@/views/admin/pwo/index.vue";
import ProfessionalWorkoutCreate from "@/views/admin/pwo/create/index.vue";
import ProfessionalWorkoutEdit from "@/views/admin/pwo/edit/index.vue";
import Exercises from "@/views/admin/exercises/index.vue";
import CreateExcercise from "@/views/admin/exercises/create/index.vue";
import EditExercise from "@/views/admin/exercises/edit/index.vue";
import ProTrainers from "@/views/admin/pro-trainers/index.vue";
import ProTrainersEditSet from "@/views/admin/pro-trainers/EditSet.vue";
import ProTrainersCreateSet from "@/views/admin/pro-trainers/CreateSet.vue";
import ProTrainersSettings from "@/views/admin/pro-trainers/Settings.vue";
import ProTrainersTypeOfBody from "@/views/admin/pro-trainers/TypeOfBody.vue";
import ProTrainersTypeOfExercise from "@/views/admin/pro-trainers/TypeOfExercise.vue";
import Logs from "@/views/admin/logs/index.vue";
import OnDemand from "@/views/admin/on-demand/index.vue";
import OnDemandCreateSet from "@/views/admin/on-demand/create/index.vue";
import OnDemandEditSet from "@/views/admin/on-demand/edit/index.vue";
import FeedbackList from "@/views/admin/feedback/index.vue";
import FeedbackDetail from "@/views/admin/feedback/detail/index.vue";
import FeedbackSettings from "@/views/admin/feedback/settings/index.vue";
import Tutorials from "@/views/admin/tutorials/index.vue";
import TutorialsCreate from "@/views/admin/tutorials/create/index.vue";
import TutorialsEdit from "@/views/admin/tutorials/edit/index.vue";
import TutorialsCategories from "@/views/admin/tutorials/categories/index.vue";
import ProgramsList from "@/views/admin/programs/index.vue";
import ProgramCreate from "@/views/admin/programs/create/index.vue";
import ProgramEdit from "@/views/admin/programs/edit/index.vue";
import AppConfig from "@/views/admin/app-config/index.vue";
import BackgroundAppImageList from "@/views/admin/app-config/bg-app/index.vue";
import BackgroundAppImageCreate from "@/views/admin/app-config/bg-app/create/index.vue";
import BackgroundAppImageEdit from "@/views/admin/app-config/bg-app/edit/index.vue";
import FeaturedSplash from '@/views/admin/app-config/featured-splash/index.vue';
import FeaturedSplashCreate from '@/views/admin/app-config/featured-splash/create/index.vue';
import AchievementsList from "@/views/admin/achievements/index.vue";
import AchievementsCreate from "@/views/admin/achievements/create/index.vue";
import AchievementsEdit from "@/views/admin/achievements/edit/index.vue";
import AchievementsTypes from "@/views/admin/achievements/types/index.vue"
import AchievementsCategories from "@/views/admin/achievements/categories/index.vue"
import AchievementsIntervals from "@/views/admin/achievements/intervals/index.vue"
import MaxProDevicesList from "@/views/admin/maxpro-devices/index.vue";
import SubscriptionsManageList from "@/views/admin/subscriptions-manage/index.vue";
import SubscriptionsManageCreate from "@/views/admin/subscriptions-manage/create/index.vue";
import SubscriptionsManageEdit from "@/views/admin/subscriptions-manage/edit/index.vue";
import SubscriptionsGetted from "@/views/admin/subscriptions-getted/index.vue";
import TrainerList from "@/views/admin/trainers/index.vue";
import TrainerCreate from "@/views/admin/trainers/create/index.vue";
import TrainerEdit from "@/views/admin/trainers/edit/index.vue";
import RequestsExercisesList from "@/views/admin/requests-exercises/index.vue";
import RequestExerciseShow from "@/views/admin/requests-exercises/show/index.vue";
import TipsList from "@/views/admin/tips/index.vue";
import TipsCreate from "@/views/admin/tips/create/index.vue";
import TipsEdit from "@/views/admin/tips/edit/index.vue";
import TipsCategories from "@/views/admin/tips/categories/index.vue";
import PreSetsList from "@/views/admin/pre-sets/index.vue";
import PreSetsCreate from "@/views/admin/pre-sets/create/index.vue";
import PreSetsEdit from "@/views/admin/pre-sets/edit/index.vue";
import PreSetsFilters from "@/views/admin/pre-sets/filters/index.vue";
import ArticlesCategories from "@/views/admin/articles/categories/index.vue";
import ArticlesList from "@/views/admin/articles/index.vue";
import ArticlesCreate from "@/views/admin/articles/create/index.vue";
import ArticlesEdit from "@/views/admin/articles/edit/index.vue";
import LivesList from "@/views/admin/lives/index.vue";
import LivesCreate from "@/views/admin/lives/create/index.vue";
import LivesEdit from "@/views/admin/lives/edit/index.vue";
import LivesFilters from "@/views/admin/lives/filters/index.vue";
import FirmwareVersionList from "@/views/admin/firmware-versions/index.vue";
import FirmwareVersionCreate from "@/views/admin/firmware-versions/create/index.vue";
import FirmwareVersionEdit from "@/views/admin/firmware-versions/edit/index.vue";
import CouponsList from "@/views/admin/coupons/index.vue";
import CouponsCreate from "@/views/admin/coupons/create/index.vue";
import CouponsEdit from "@/views/admin/coupons/edit/index.vue";
import ExercisesByUsersList from "@/views/admin/exercises-by-users/index.vue";
import ExerciseByUserShow from "@/views/admin/exercises-by-users/show/index.vue";
import DeleteAccount from "@/views/deleteAccount/DeleteAccount.vue"

export default [{
    path: "/",
    name: "Home",
    component: Home,
    meta: { forVisitors: true },
},
{
    path: "/delete-account",
    name: "Delete Account",
    component: DeleteAccount,
    meta: { forVisitors: true },
},
{
    path: "/admin/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { forAdmin: true },
},
{
    path: "/admin/push-notifications",
    name: "Push Notifications List",
    component: PushNotifications,
    meta: { forAdmin: true },
},
{
    path: "/admin/push-notifications/edit/:id",
    name: "Push Notification Edit",
    component: PushNotificationsEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/push-notifications/send/:uid",
    name: "Push Notification Send",
    component: PushNotificationsSend,
    meta: { forAdmin: true },
},
{
    path: "/admin/push-notifications-by-group/",
    name: "Push Notification By Group List",
    component: PushNotificationsByGroupList,
    meta: { forAdmin: true },
},
{
    path: "/admin/push-notifications-by-group/send/:id",
    name: "Push Notification By Group Send",
    component: PushNotificationsByGroupSend,
    meta: { forAdmin: true },
},
{
    path: "/admin/push-notifications/create",
    name: "Push Notification Create",
    component: PushNotificationsCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/pwo",
    name: "Professional Workout List",
    component: ProfessionalWorkout,
    meta: { forAdmin: true },
},
{
    path: "/admin/exercises",
    name: "Exercises List",
    component: Exercises,
    meta: { forAdmin: true },
},
{
    path: "/admin/exercises/create/",
    name: "Create Excercise",
    component: CreateExcercise,
    meta: { forAdmin: true },
},
{
    path: "/admin/exercises/edit/:id",
    name: "Edit Exercise",
    component: EditExercise,
    meta: { forAdmin: true },
},
{
    path: "/admin/user-list",
    name: "User List",
    component: UserList,
    meta: { forCustomerService: true },
    // children: [
    //   {
    //     path: "user/:id",
    //     name: "user",
    //     component: User,
    //     meta: { forCustomerService: true },
    //   },
    // ],
},
{
    path: "/admin/user-list/user/:uid",
    name: "User Profile",
    component: User,
    meta: { forCustomerService: true },
},
{
    path: "/admin/coached-classes",
    name: "CoachedClasses List",
    component: ProTrainers,
    meta: { forAdmin: true },
},
{
    path: "/admin/coached-classes/create-set",
    name: "CoachedClasses Create Set",
    component: ProTrainersCreateSet,
    meta: { forAdmin: true },
},
{
    path: "/admin/coached-classes/:id",
    name: "CoachedClasses Edit Set",
    component: ProTrainersEditSet,
    meta: { forAdmin: true },
},
{
    path: "/admin/coached-classes/settings",
    name: "CoachedClasses Settings",
    component: ProTrainersSettings,
    meta: { forAdmin: true },
},
{
    path: "/admin/coached-classes/type-of-body",
    name: "CoachedClasses Type Of Body",
    component: ProTrainersTypeOfBody,
    meta: { forAdmin: true },
},
{
    path: "/admin/coached-classes/type-of-exercise",
    name: "CoachedClasses Type Of Exercise",
    component: ProTrainersTypeOfExercise,
    meta: { forAdmin: true },
},
{
    path: "/admin/pwo/create",
    name: "ProfessionalWorkout Create",
    component: ProfessionalWorkoutCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/pro-trainers/edit/:id",
    name: "ProfessionalWorkout Edit",
    component: ProfessionalWorkoutEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/logs",
    name: "Logs",
    component: Logs,
    meta: { forCustomerService: true },
},
{
    path: "/admin/on-demand",
    name: "On Demand List",
    component: OnDemand,
    meta: { forAdmin: true },
},
{
    path: "/admin/on-demand/create",
    name: "On Demand Create",
    component: OnDemandCreateSet,
    meta: { forAdmin: true },
},
{
    path: "/admin/on-demand/edit/:id",
    name: "On Demand Edit Set",
    component: OnDemandEditSet,
    meta: { forAdmin: true },
},
{
    path: "/admin/feedback",
    name: "Feedback List",
    component: FeedbackList,
    meta: { forAdmin: true },
},
{
    path: "/admin/feedback/:id",
    name: "Feedback Detail",
    component: FeedbackDetail,
    meta: { forAdmin: true },
},
{
    path: "/admin/feedback/settings",
    name: "Feedback Settings",
    component: FeedbackSettings,
    meta: { forAdmin: true },
},
{
    path: "/admin/tutorials",
    name: "Tutorials List",
    component: Tutorials,
    meta: { forAdmin: true },
},
{
    path: "/admin/tutorials/create",
    name: "Tutorials Create",
    component: TutorialsCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/tutorials/edit/:id",
    name: "Tutorials Edit",
    component: TutorialsEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/tutorials/categories/",
    name: "Tutorials Categories",
    component: TutorialsCategories,
    meta: { forAdmin: true },
},
{
    path: "/admin/programs",
    name: "Programs List",
    component: ProgramsList,
    meta: { forAdmin: true },
},
{
    path: "/admin/programs/create",
    name: "Program Create",
    component: ProgramCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/programs/edit/:id",
    name: "Program Edit",
    component: ProgramEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config",
    name: "App Config",
    component: AppConfig,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config/bg-app",
    name: "Background App Image List",
    component: BackgroundAppImageList,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config/bg-app/create",
    name: "Background App Create",
    component: BackgroundAppImageCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config/bg-app/edit/:id",
    name: "Background App Edit",
    component: BackgroundAppImageEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config/featured-splash",
    name: "Featured Splash Screen",
    component: FeaturedSplash,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config/featured-splash/create",
    name: "Featured Splash Screen Create",
    component: FeaturedSplashCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/app-config/featured-splash/edit/:id",
    name: "App Splash Edit",
    component: FeaturedSplashCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/achievements",
    name: "Achievements List",
    component: AchievementsList,
    meta: { forAdmin: true },
},
{
    path: "/admin/achievements/create",
    name: "Achievements Create",
    component: AchievementsCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/achievements/edit/:id",
    name: "Achievements Edit",
    component: AchievementsEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/achievements/types/",
    name: "Achievements Types",
    component: AchievementsTypes,
    meta: { forAdmin: true },
},
{
    path: "/admin/achievements/categories/",
    name: "Achievements Categories",
    component: AchievementsCategories,
    meta: { forAdmin: true },
},
{
    path: "/admin/achievements/intervals/",
    name: "Achievements Intervals",
    component: AchievementsIntervals,
    meta: { forAdmin: true },
},
{
    path: "/admin/maxpro-devices",
    name: "MAXPRO Devices List",
    component: MaxProDevicesList,
    meta: { forAdmin: true },
},
{
    path: "/admin/subscriptions-manage",
    name: "Subscriptions Manage List",
    component: SubscriptionsManageList,
    meta: { forAdmin: true },
},
{
    path: "/admin/subscriptions-manage/create",
    name: "Subscriptions Manage Create",
    component: SubscriptionsManageCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/subscriptions-manage/edit/:id",
    name: "Subscriptions Manage Edit",
    component: SubscriptionsManageEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/subscriptions-getted",
    name: "Subscriptions Getted List",
    component: SubscriptionsGetted,
    meta: { forAdmin: true },
},
{
    path: "/admin/trainers",
    name: "Trainer List",
    component: TrainerList,
    meta: { forAdmin: true },
},
{
    path: "/admin/trainers/create",
    name: "Trainer Create",
    component: TrainerCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/trainers/edit/:id",
    name: "Trainer Edit",
    component: TrainerEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/requests-exercises/",
    name: "Requests Exercises",
    component: RequestsExercisesList,
    meta: { forAdmin: true },
},
{
    path: "/admin/requests-exercises/show/:idRequest",
    name: "Request Exercise Show",
    component: RequestExerciseShow,
    meta: { forAdmin: true },
},
{
    path: "/admin/tips",
    name: "Tips List",
    component: TipsList,
    meta: { forAdmin: true },
},
{
    path: "/admin/tips/create",
    name: "Tips Create",
    component: TipsCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/tips/edit/:id",
    name: "Tips Edit",
    component: TipsEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/tips/categories/",
    name: "Tips Categories",
    component: TipsCategories,
    meta: { forAdmin: true },
},
{
    path: "/admin/pre-sets",
    name: "Pre Sets List",
    component: PreSetsList,
    meta: { forAdmin: true },
},
{
    path: "/admin/pre-sets/create",
    name: "Pre Sets Create",
    component: PreSetsCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/pre-sets/edit/:id",
    name: "Pre Sets Edit",
    component: PreSetsEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/pre-sets/filters/",
    name: "Pre Sets Filters",
    component: PreSetsFilters,
    meta: { forAdmin: true },
},
{
    path: "/admin/articles/categories/",
    name: "Articles Categories",
    component: ArticlesCategories,
    meta: { forAdmin: true },
},
{
    path: "/admin/articles",
    name: "Articles List",
    component: ArticlesList,
    meta: { forAdmin: true },
},
{
    path: "/admin/articles/create",
    name: "Articles Create",
    component: ArticlesCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/articles/edit/:id",
    name: "Articles Edit",
    component: ArticlesEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/lives",
    name: "Lives List",
    component: LivesList,
    meta: { forAdmin: true },
},
{
    path: "/admin/lives/create",
    name: "Lives Create",
    component: LivesCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/lives/edit/:id",
    name: "Lives Edit",
    component: LivesEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/lives/filters",
    name: "Lives Filters",
    component: LivesFilters,
    meta: { forAdmin: true },
},
{
    path: "/admin/firmware-versions",
    name: "Firmware Version List",
    component: FirmwareVersionList,
    meta: { forAdmin: true },
},
{
    path: "/admin/firmware-versions/create",
    name: "Firmware Version Create",
    component: FirmwareVersionCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/firmware-versions/edit/:id",
    name: "Firmware Version Edit",
    component: FirmwareVersionEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/coupons",
    name: "Coupons List",
    component: CouponsList,
    meta: { forAdmin: true },
},
{
    path: "/admin/coupons/create",
    name: "Coupons Create",
    component: CouponsCreate,
    meta: { forAdmin: true },
},
{
    path: "/admin/coupons/edit/:id",
    name: "Coupons Edit",
    component: CouponsEdit,
    meta: { forAdmin: true },
},
{
    path: "/admin/exercises-by-users",
    name: "Exercises By Users List",
    component: ExercisesByUsersList,
    meta: { forAdmin: true },
},
{
    path: "/admin/exercises-by-users/show/:id",
    name: "Exercise By User Show",
    component: ExerciseByUserShow,
    meta: { forAdmin: true },
},

];