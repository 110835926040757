<template>
  <div class="container">
    <div v-if="item.author" class="author-container">
      <img
        v-if="item.author.photoURL"
        :src="item.author.photoURL"
        class="author-image"
        alt=""
      />
      <img
        v-else
        class="author-image"
        :src="require('@/assets/default-avatar.png')"
      />
      <span class="author-name">{{
        item.author.nickname ?? item.author.displayName
      }}</span>
    </div>
    <span class="post-message">{{ item.message }}</span>
    <div class="images-container">
      <img
        class="post-image"
        v-bind:key="img.link"
        v-for="img in item.resources"
        :src="img.link"
        alt=""
        :width="`${96 / item.resources.length}%`"
        :height="`${350 / item.resources.length}px`"
      />
    </div>

    <div class="pump-container" :title="item.likes.length">
      <img :src="require('@/assets/pump_orange.svg')" class="pump" />
      <span class="pump-number">{{ item.likes.length }}</span>
      <span v-if="item.likes.length > 0" class="pump-text">{{
        getPumpsText(item.likes)
      }}</span>
    </div>

    <span class="comments-title">Comments</span>

    <div v-if="item.comments.length > 0" class="comments-container">
      <div
        class="comment"
        v-bind:key="comment.id"
        v-for="comment in item.comments"
      >
        <img
          v-if="comment.user.photoURL"
          :src="comment.user.photoURL"
          class="author-image"
          alt=""
        />
        <img
          v-else
          class="author-image"
          :src="require('@/assets/default-avatar.png')"
        />
        <div class="comment-text-container">
          <span class="author-name">{{
            comment.user.nickname ?? comment.user.displayName
          }}</span>
          <span class="comment-text">{{ comment.comment }}</span>
        </div>
      </div>
    </div>
    <div v-else class="mt-4">There's no comments for this post.</div>
  </div>
</template>

<script>
export default {
  name: "post-detail",
  model: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  methods: {
    getPumpsText(pumps) {
      const pumpUser = pumps[0].user;
      if (pumps?.length === 1) {
        return `${pumpUser.nickname ?? pumpUser.displayName} pumped this post`;
      } else {
        return `${pumpUser.nickname ?? pumpUser.displayName} and ${
          pumps.length - 1
        } more pumped this post`;
      }
    },
  },
};
</script>

<style scoped>
.author-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.author-image {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  object-fit: cover;
}
.author-name {
  font-size: 16px;
  font-weight: bold;
  color: #3a3a3a;
}
.post-message {
  padding: 16px 0;
  color: #3a3a3a;
}
.images-container {
  max-width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.post-image {
  /* height: 200px; */
  object-fit: cover;
  /* width: 100px; */
}
.pump-container {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-color: #e4e4e4;
  border-bottom-style: solid;
}
.pump {
  height: 24px;
  width: 24px;
}
.pump-number {
  margin-left: 8px;
  font-weight: bold;
  color: #ed702d;
}
.pump-text {
  margin-left: 8px;
  font-size: 14px;
  color: #3a3a3a;
}
.comments-container {
  margin-top: 16px;
}
.comments-title {
  font-size: 18px;
  font-weight: bold;
  color: #3a3a3a;
}
.comment {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
}
.comment-text {
  font-size: 16px;
  color: #3a3a3a;
}
.comment-text-container {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
</style>
