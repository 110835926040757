import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import AuthModule from './modules/auth'
import LogsModule from './modules/logs'
import ExercisesModule from './modules/exercises'
import DashboardModule from './modules/dashboard'
import PushNotificationsModule from './modules/push-notifications'
import ProTrainersModule from './modules/pro-trainers'
import OnDemandModule from './modules/on-demand'
import PwoModule from './modules/pwo'
import FeedbackModule from './modules/feedback'
import TutorialsModule from './modules/tutorials'
import ProgramsModule from './modules/programs'
import AppConfig from './modules/app-config'
import AchievementsModule from './modules/achievements'
import MaxProDevicesModule from './modules/maxpro-devices'
import SubcriptionsModule from './modules/subscriptions'
import TrainersModule from './modules/trainers'
import RequestsExercisesModule from './modules/requests-exercises'
import TipsModule from './modules/tips'
import PreSetsModule from './modules/pre-sets'
import ArticlesModule from './modules/articles'
import LivesModule from './modules/lives'
import FirmwareVersionsModule from './modules/firmware-versions'
import CouponsModule from './modules/coupons'
import PostsModule from './modules/posts';

export default new Vuex.Store({
    modules: {
        AuthModule,
        LogsModule,
        ExercisesModule,
        DashboardModule,
        PushNotificationsModule,
        ProTrainersModule,
        OnDemandModule,
        PwoModule,
        FeedbackModule,
        TutorialsModule,
        ProgramsModule,
        AppConfig,
        AchievementsModule,
        MaxProDevicesModule,
        SubcriptionsModule,
        TrainersModule,
        RequestsExercisesModule,
        TipsModule,
        PreSetsModule,
        ArticlesModule,
        LivesModule,
        FirmwareVersionsModule,
        CouponsModule,
        PostsModule
    }
})